<template>
  <v-card
    outlined
    class="gift-card d-flex flex-column flex-md-row align-center justify-center justify-md-space-between px-3 w-100"
    :id="`git-card-${product.slug}`"
    ref="giftCard"
    :key="componentKey"
  >
    <v-row>
      <v-col cols="12" md="2" class="d-flex justify-center align-center mt-2"
        ><img
          :src="product.mediaURL"
          onerror="this.onerror=null;this.src='/no-icon.png'"
          class="product-img align-self-center"
          :alt="'Immagine' + product.name"
          :title="
            product.name + ' (' + product.codInt + '-' + product.codVar + ')'
          "
      /></v-col>
      <v-col
        cols="12"
        md="4"
        class="description d-flex flex-column justify-center align-md-start align-center"
      >
        <span class="stamps">
          {{ product.productInfos["POINT_CARD_AMOUNT"] }}
          {{ product.shortDescr }}
        </span>
        <span class="name">
          <strong>{{ product.name }}</strong>
        </span>
        <div v-if="item && item.cartItemInfo && item.cartItemInfo.size">
          {{ item.cartItemInfo.size }}
        </div>
      </v-col>
      <v-col cols="12" md="1" class="d-flex justify-center align-center">
        <ProductPrice v-if="product.price" :product="product"
      /></v-col>
      <v-col
        cols="12"
        md="5"
        class="gift-actions d-flex flex-column flex-md-row justify-center justify-md-end align-center"
        ><v-card-actions
          class="w-100"
          v-if="product.available > 0"
          @click.stop.prevent
          :key="componentKey"
        >
          <template v-if="item != null">
            <v-btn
              x-large
              block
              color="primary lighten-1"
              outlined
              @click="handleRemove(item)"
            >
              {{ $t("product.rewards.removeFromCart") }}
            </v-btn>
          </template>
          <template v-else>
            <v-row no-gutters class="justify-center align-center">
              <v-col cols="12" class="pa-1"
                ><div
                  class="select-options w-100 d-flex align-center justify-center"
                  v-for="selectOption in selectOptionsArray"
                  :key="selectOption.name"
                  @click.stop.prevent
                  @mousedown.stop
                >
                  <v-select
                    :disabled="!buyable"
                    dense
                    :hide-details="true"
                    outlined
                    v-model="selectedOptionData"
                    :items="selectOption.options"
                    :label="selectOption.name"
                    item-text="key"
                    item-value="value"
                  /></div
              ></v-col>
              <v-col cols="12" class="gift-actions pa-1"
                ><v-btn
                  :disabled="!buyable"
                  class="add-to-cart"
                  color="primary lighten-1"
                  depressed
                  x-large
                  block
                  @click="handleAdd()"
                >
                  {{ $t("product.rewards.addToCart") }}
                </v-btn></v-col
              >
            </v-row>
          </template>
        </v-card-actions></v-col
      >
    </v-row>
  </v-card>
</template>
<style lang="scss">
.gift-card {
  .product-img {
    max-width: 100%;
  }
  .product-price {
    .product-descr-weight,
    .old-price {
      display: none;
    }
  }
}
</style>
<script>
import ProductPrice from "./ProductPrice.vue";
//import ProductQty from "./ProductQty.vue";

//import SelectedOptionsMenu from "@/components/product/SelectedOptionsMenu.vue";

import productMixin from "~/mixins/product";

import { mapState, mapGetters } from "vuex";

import cloneDeep from "lodash/cloneDeep";
import LongPress from "vue-directive-long-press";
import { mask } from "vue-the-mask";

export default {
  name: "GiftCard",
  props: {
    product: { type: Object, required: true },
    position: { type: Number, default: undefined },
    horizontal: { type: Boolean, required: false, default: true },
    minAmount: { type: Number, required: false, default: 30.0 },
    selectedOption: { type: String, required: false }
  },
  directives: {
    "long-press": LongPress,
    mask: mask
  },
  components: {
    ProductPrice
    //ProductQty,
    //SelectedOptionsMenu
  },
  mixins: [productMixin],
  data() {
    return {
      selectOptionsArray: [],
      selectedOptions: {},
      componentKey: 0,
      key: 0,
      item: null,
      selectedOptionData: null
    };
  },
  computed: {
    ...mapGetters({
      getItem: "cart/getItem",
      isAuthenticated: "cart/isAuthenticated"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    promoProductClasses() {
      return this.product.productClasses.filter(
        pClass => pClass.productClassGroupId != 10002
      );
    },
    // item() {
    //   let item = this.getItem({
    //     product: this.product,
    //     ...this.selectedOptions,
    //     size: this.selectedOption
    //   });
    //   return item;
    // },
    buyable() {
      var netTotal = this.cart.orderId
        ? this.cart.netTotal
        : this.cart.totalPrice;
      // if (this.cart.orderAdjustmentSummary) {
      //   var discountItemTotal = this.cart.orderAdjustmentSummary.TYPE_ITEM_TOTAL
      //     .totalVariation;
      //   netTotal += discountItemTotal;
      // }
      if (this.cart?.taxTotal) {
        netTotal += this.cart.taxTotal;
      }
      if (netTotal > this.minAmount) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    async handleAdd() {
      let payload = {
        product: this.product,
        quantity: 1
      };
      if (this.selectedOptionData) {
        payload.params = {
          infos: { ...this.selectedOptions, size: this.selectedOptionData }
        };
      }
      let res = await this.addProduct(payload);
      if (res) {
        this.item = this.getItem({
          product: this.product
        });
        console.log("ggg", this.item);
      }
      this.componentKey++;
      this.$emit("selectProduct");
    },
    handleRemove(elem) {
      this.removeItem(elem);
      this.selectedOptionData = null;
      this.item = null;
      this.componentKey++;
    },
    async setSelectOptions() {
      await this.needLogin("selectoptionsmenu");
    },
    handleClick() {
      this.$emit("selectProduct");
    },
    checkSelectOption() {
      // there is buy limit to 1 piece by specs
      let selectOptions = this.selectOptionsArray[0].options;
      let item = null;
      for (let i = 0; i < selectOptions.length; i++) {
        let selectOption = selectOptions[i].key;
        let params = {
          product: this.product
        };
        params[this.selectOptionsArray[0].parameterName] = selectOption;
        item = this.getItem(params);
        if (item) {
          this.selectedOptions = {};
          this.selectedOptions[
            this.selectOptionsArray[0].parameterName
          ] = selectOption;
          break;
        }
      }
    }
  },
  mounted() {
    if (this.product.selectOptions.length > 0) {
      this.selectOptionsArray = cloneDeep(this.product.selectOptions);
      this.checkSelectOption();
    }

    if (this.cart.cartItems) {
      let filteredItems = this.cart.cartItems.filter(
        item =>
          item.product.productInfos.PRODUCT_NATURE_ID &&
          item.product.productInfos.PRODUCT_NATURE_ID === "8"
      );
      if (filteredItems.length == 0) {
        this.selectedOptionData = null;
        this.item = null;
      } else {
        this.selectedOptionData = this.selectedOption;
        this.item = filteredItems[0];
      }
    }
  },
  created() {
    global.EventBus.$on("removeGift", item => {
      this.handleRemove(item);
    });
    global.EventBus.$on("removeAllGift", items => {
      for (var i = 0; i < items.length; i++) {
        if (
          items[i].product.productInfos &&
          items[i].product.productInfos.PRODUCT_NATURE_ID &&
          items[i].product.productInfos.PRODUCT_NATURE_ID === "8"
        ) {
          this.handleRemove(items[i]);
        }
      }
    });
  },
  watch: {
    "cart.cartItems": function(items) {
      let filteredItems = items.filter(
        item =>
          item.product.productInfos.PRODUCT_NATURE_ID &&
          item.product.productInfos.PRODUCT_NATURE_ID === "8"
      );
      if (filteredItems.length == 0) {
        this.selectedOptionData = null;
        this.item = null;
      }
    }
  }
};
</script>
